import React from "react"
import { arrayOf, shape, PublicationType } from "../types"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"

function PublicationCard ({publication}){
    return(
        <div className="flex flex-col items-center overflow-hidden shadow-md rounded">
            <div className="flex w-full">
            { (publication.coverImage !== null)?
                (
                    (!!publication.videolink) ?
                    <a 
                        href={publication.videolink}
                        rel="noreferrer noopener"
                        target="_blank"
                        className="hover:cursor-pointer"
                    >
                        <GatsbyImage
                            image={publication.coverImage.childImageSharp.gatsbyImageData}
                            alt={publication.title}
                            imgStyle={{ objectFit: 'contain' }}
                            className="my-auto"
                        />
                    </a>:
                    <GatsbyImage
                        image={publication.coverImage.childImageSharp.gatsbyImageData}
                        alt={publication.title}
                        imgStyle={{ objectFit: 'contain' }}
                        className="my-auto"
                    />
                )
                 :
                <StaticImage
                    src="../content/notfound.png"
                    alt="head image not found"
                    className="my-auto"
                />
            }
            </div>
            
            <span className="text-black text-center my-2 px-2">
                {publication.title}
            </span>
            <span className="text-gray-700 text-center underline mt-auto mb-1 px-2 ">
                {publication.proceedings}
            </span>
            <span className="text-red-400 text-center mb-4 ">
                {publication.award[0]}
            </span>
            
        </div>
    );
}

const SelectedWorks = ({ publications }) => {
    
    return (
    <div className="w-full my-12" id="SelectedWorks">
        <div className="text-center text-black text-2xl md:text-4xl my-8">Selected Works</div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-3/4 mx-auto">
            {!!publications ?
                publications.map((publication, i) => (
                    <PublicationCard 
                        key={`selpub_${i}`}
                        publication={publication} 
                    />
                ))
                :""
            }
        </div>
    </div>
    )
}

SelectedWorks.propTpyes = {
    publications: arrayOf(shape(PublicationType))
}

export default SelectedWorks