import { graphql } from "gatsby"
import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Publications from "../components/publications/publications"
import Profile from "../components/profile"
import SelectedWorks from "../components/selectedWorks"
import About from "../components/about"
import ResearchProjects from "../components/researchProjects"
import InteractiveWorks from "../components/interactiveWorks"
import ImmersiveStorys from "../components/immersiveStorys"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Publication from "../components/publications/publication"

const IndexPage = ({ data }) => {
  const { profile, selexp, selections, experience, publications, interactiveWorks, immersiveStory, researchCat } = data

  console.log(data);
  return (
    <Layout>
      <SEO title="Home" />
      <div>
        <Profile profiledata={profile} selexp={selexp} />
        <SelectedWorks publications={selections.nodes} />
        {/* <About exps={experience.nodes} /> */}
        <ResearchProjects 
          publications={publications.nodes} 
          researchCat={researchCat.nodes}
          fullName={profile.ename}
        />
        <InteractiveWorks 
          publications={interactiveWorks.nodes} 
          fullName={profile.ename} 
        />
        <ImmersiveStorys 
          publications={immersiveStory.nodes} 
          fullName={profile.ename} 
        />
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    profile: profileYaml {
      ...ProfileFragment
    }
    
    experience: allExperienceYaml {
      nodes{
        ...ExperienceFragment
      }
    }

    selexp: experienceYaml(selection: {eq: true}) {
      ...ExperienceFragment
    }
    
    researchCat: allResearchCatYaml{
      nodes{
        ...ResearchCatFragment
      }
    }
    
    publications: allPublicationsYaml {
      nodes {
        ...PublicationFragment
      }
    }

    selections: allPublicationsYaml (filter: {selection: {eq: true}}) {
      nodes {
        ...PublicationFragment
      }
    }

    interactiveWorks: allPublicationsYaml (filter: {interactiveWorks: {eq: true}}) {
      nodes {
        ...PublicationFragment
      }
    }

    immersiveStory: allPublicationsYaml (filter: {immersiveStory: {eq: true}}) {
      nodes {
        ...PublicationFragment
      }
    }
  }
`