import { graphql } from "gatsby"
import { arrayOf, shape, object, string } from "prop-types"

export const ProfileType = {
    cname: string.isRequired,
    ename: string.isRequired,
    intro: string,
    image: shape({
        childImageSharp: object.isRequired,
        publicURL: string.isRequired,
    }),
    email: arrayOf(string),
    caddress: string,
    eaddress: string,
    socialmedia: arrayOf(string)
}

export const query = graphql`
    fragment ProfileFragment on ProfileYaml{
        cname
        ename
        intro
        image{
            childImageSharp{
                gatsbyImageData(width: 512, quality: 90)
            }
            publicURL
        }
        email
        caddress
        eaddress
        socialmedia
    }
`