import React from "react"
import { ProfileType, ExperienceType } from "../types"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"

import { SiGooglescholar } from "react-icons/si"
import { FaYoutube } from "react-icons/fa"


const Profile = ({ profiledata, selexp }) => {

    return (
    <div className="w-full bg-gray-200 py-6">
        <div className="flex flex-col md:flex-row w-3/4 mx-auto my-8">
            <div className="flex flex-col sm:w-1/3 items-center justify-center">
                { (profiledata.image !== null)?
                    <GatsbyImage
                        image={profiledata.image.childImageSharp.gatsbyImageData}
                        alt={profiledata.ename}
                        imgStyle={{ objectFit: 'contain' }}
                        className="rounded"
                    /> :
                    <StaticImage
                        src="../content/notfound.png"
                        alt="head image not found"
                        className="rounded"
                    />
                }
            </div>
            <div className="flex flex-col md:w-2/3 justify-start px-6 py-6 md:py-2">
                <span className="text-black text-xl md:text-2xl">{profiledata.cname} | {profiledata.ename}</span>
                <span className="text-gray-700 text-xl mt-2">{selexp.position},</span>
                <span className="text-gray-700 text-xl">{selexp.company}</span>
                        <span className="text-gray-700 text-xl display-linebreak font-light mt-4"
                            >{profiledata.intro}
                        </span>
                <div className="flex flex-col mt-1 xl:mt-auto">
                    <div className="flex flex-row text-xl">
                        <span className="text-gray-700 mr-4 font-light">Email:</span>
                        {!!profiledata.email ? 
                            profiledata.email.map((_email, i) => (
                                <span className="text-gray-700 mx-2 font-thin hover:opacity-25 transition-opacity duration-100" key={`email_${i}`}>
                                    {_email}
                                </span>
                            ))
                            :""
                        }
                    </div>
                    <div className="flex flex-col text-xl">
                        <span className="text-gray-700 mr-4 font-light mt-1">More Information:</span>
                        {!!profiledata.socialmedia ? 
                            profiledata.socialmedia.map((link, i) => (
                                <a 
                                    href={link}
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    className="text-gray-700 mr-4" 
                                    key={`link_${i}`}
                                >
                                    {
                                        link.includes("scholar") ?  <span className="inline-block text-gray-700 font-thin hover:opacity-25 transition-opacity duration-100"><span className="inline-block">Publications: </span> <SiGooglescholar className="inline-block w-6 aspect-square" /></span> :
                                        link.includes("youtube") ?  <span className="inline-block text-gray-700 font-thin hover:opacity-25 transition-opacity duration-100"><span className="inline-block">Videos: </span><FaYoutube className="inline-block w-6 aspect-square" /></span> :
                                        {link}
                                    }
                                </a>
                            ))
                            :""
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

Profile.propTpyes = {
    profiledata: ProfileType,
    selexp: ExperienceType
}

export default Profile