import React from "react"
import { GiLaurelsTrophy, GiLaurels } from "react-icons/gi"
import { RiFilePaper2Line } from "react-icons/ri"
import { SiGooglescholar } from "react-icons/si"
import { arrayOf, shape, String, PublicationType } from "../types"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"

function AuthorSolver({authors, fullName}){
    let authorArr = authors.split(", ");
    return (
        <>
            {authorArr.map((authorName, i) => (
                <span 
                    className={`${"inline-block "}${(i !== authorArr.length -1) ? "mr-2" : ""}${(fullName === authorName) ? " underline" : ""}`}
                >
                    {authorName}{(i !== authorArr.length -1) ? "," : "" }
                </span>
            ))}
        </>
    )
}

function AwardList({ award }){
    return award === "" ? "" : (
        <div className="flex flex-row w-full mb-1">
            <GiLaurels className="inline-block w-4 aspect-square mt-0.5" />
            <span className="w-full mr-1">{award}</span>
        </div>
    );
}

function CoverImageHandler({image, alt}){
    return(
        <>
            { (image !== null)?
                <GatsbyImage
                    image={image.childImageSharp.gatsbyImageData}
                    alt={alt}
                    imgStyle={{ objectFit: 'contain' }}
                    className="mb-auto"
                /> :
                <StaticImage
                    src="../content/notfound.png"
                    alt="head image not found"
                    className="mb-auto"
                />
            }
        </>
    );
}

function PublicationCard ({ publication, fullName }){
    return(
        <div className="flex flex-col md:flex-row overflow-hidden">
            <div className="flex md:aspect-square md:w-1/3 mb-6">
                {!!publication.videolink?
                    <a 
                        href={publication.videolink}
                        rel="noreferrer noopener"
                        target="_blank"
                        className="hover:cursor-pointer"
                    >
                        <CoverImageHandler 
                            image={publication.coverImage}
                            alt={publication.title}
                        />
                    </a>:
                    <CoverImageHandler 
                        image={publication.coverImage}
                        alt={publication.title}
                    />
                }
                
            </div>
            <div className="flex flex-col md:w-2/3 h-full px-2 items-start">
                <span className="text-black text-base">
                    {publication.title}
                    <a 
                        href={"https://scholar.google.com/scholar?hl=zh-TW&as_sdt=0%2C5&q=" + encodeURIComponent(publication.title) + "&btnG="}
                        rel="noreferrer noopener"
                        target="_blank"
                        className="inline-block mt-1 items-center"
                    >
                        <SiGooglescholar className="w-6 aspect-square text-black hover:opacity-25 transition-opacity duration-100" />
                    </a>
                    {!!publication.dwlink ?
                        <a 
                            href={publication.dwlink}
                            rel="noreferrer noopener"
                            target="_blank"
                            className="inline-block ml-1 mt-1 items-center"
                        >
                            <RiFilePaper2Line className="w-6 aspect-square text-black hover:opacity-25 transition-opacity duration-100" />
                        </a>
                        :""
                    }
                </span>
                <span className="text-gray-700 text-sm font-light my-2">
                    <AuthorSolver authors={publication.authors} fullName={fullName} />
                </span>
                <span className="text-gray-700 text-sm font-light mb-2">
                    {publication.proceedings}
                </span>
                <span className="text-red-400 text-xs font-bold flex flex-col">
                    {!!publication.award ?
                        publication.award.map((_award, i) => (
                            <AwardList award={_award} key={`${publication.title}_awd_${i}`} />
                        )):""
                    }
                </span>
            </div>
        </div>
    );
}

const InteractiveWorks = ({ publications, fullName }) => {
    return (
    <div className="w-full my-6 bg-gray-300 py-8" id="InteractiveWorks">
        <div className="text-center text-black text-2xl md:text-4xl my-8">Interactive Works</div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-3/4 mx-auto">
            {!!publications ?
                publications.map((publication, i) => (
                    <PublicationCard 
                        key={`selpub_${i}`}
                        publication={publication}
                        fullName={fullName}
                    />
                ))
                :""
            }
        </div>
    </div>
    )
}

InteractiveWorks.propTpyes = {
    publications: arrayOf(shape(PublicationType)),
    fullName: String
}

export default InteractiveWorks