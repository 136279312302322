import { graphql } from "gatsby"
import { shape, string, object } from "prop-types"

export const PublicationType = {
    title: string.isRequired,
    authors: string.isRequired,
    proceedings: string,
    published: string,
    researchCat: string,
    coverImage: shape({
        childImageSharp: object.isRequired,
    }),
    award: string,
    dwlink: string,
    videolink: string
}

export const query = graphql`
    fragment PublicationFragment on PublicationsYaml{
        title
        authors
        proceedings
        published
        researchCat
        coverImage {
            childImageSharp {
                gatsbyImageData(width: 640, quality: 90)
            }
            publicURL
        }
        award
        dwlink
        videolink
    }
`