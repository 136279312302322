import { graphql } from "gatsby"
import { arrayOf, string, bool } from "prop-types"

export const ExperienceType = {
    selection: bool,
    company: string.isRequired,
    department: string,
    totime: string,
    fromtime: string,
    url: string,
    label: string,
    notes: arrayOf(string)
}

export const query = graphql`
    fragment ExperienceFragment on ExperienceYaml{
        selection
        company
        department
        position
        totime
        fromtime
        url
        label
        notes
    }
`