import { graphql } from "gatsby"
import { shape, string, object } from "prop-types"

export const ResearchCatType = {
    catname: string.isRequired,
    coverImage: shape({
        childImageSharp: object.isRequired,
    }),
    description: string
}

export const query = graphql`
    fragment ResearchCatFragment on ResearchCatYaml{
        catname
        coverImage {
            childImageSharp {
                gatsbyImageData(width: 640, quality: 90)
            }
            publicURL
        }
        description
    }
`