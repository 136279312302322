import React from "react"
import { arrayOf, shape, ExperienceType } from "../types"

function ExpCard ({ exp }){
    return(
        <div className="flex flex-col my-4 text-xl text-gray-700">
            <span className="font-bold">
                {exp.position} {(exp.fromtime !== "" && exp.totime !== "")?
                    `(${exp.fromtime} ~ ${exp.totime})`:""}
            </span>
            {!!exp.department ?
                <span className="">
                    {exp.department},
                </span>
            :""}
            <span className="">
                {exp.company}
            </span>
            {!!exp.notes ?
                exp.notes.map((note, i) => (
                    <span className="" key={`${exp.company}_${exp.position}_notes_${i}`}>
                        {note}
                    </span>
                ))
            :""
            }
        </div>
    );
}

const About = ({ exps }) => {
    const eduLabel = [];
    const expLabel = [];

    for( var i=0 ; i< exps.length ; i++ ){
        if( exps[i].label === "Education" ){
            eduLabel.push(exps[i]);
        }else{
            expLabel.push(exps[i]);
        }
    }

    return (
    <div className="w-full py-8 bg-gray-300" id="About">
        <div className="text-center text-black text-4xl my-8">About</div>
        <div className="grid grid-cols-2 gap-2 w-3/4 mx-auto mt-6">
            <div className="text-black text-3xl my-4">Education:</div>
            <div className="text-black text-3xl my-4">Experiences:</div>
            <div className="flex flex-col">
                {!!eduLabel?
                    eduLabel.map((exp, i) => (
                        <ExpCard key={`edu_${i}`} exp={exp} />
                    )):
                    ""
                }
            </div>
            <div className="flex flex-col">
                {!!expLabel?
                    expLabel.map((exp, i) => (
                        <ExpCard key={`exp_${i}`} exp={exp} />
                    )):
                    ""
                }
            </div>
        </div>
    </div>
    )
}

About.propTpyes = {
    exps: arrayOf(shape(ExperienceType))
}

export default About