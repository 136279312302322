import React from "react"
import { FaYoutube } from "react-icons/fa"
import { GiLaurelsTrophy } from "react-icons/gi"
import { RiFilePaper2Line } from "react-icons/ri"
import { SiGooglescholar } from "react-icons/si"
import { arrayOf, shape, String, PublicationType, ResearchCatType } from "../types"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"

function AuthorSolver({authors, fullName}){
    let authorArr = authors.split(", ");
    return (
        <>
            {authorArr.map((authorName, i) => (
                <span 
                    className={`${"inline-block "}${(i !== authorArr.length -1) ? "mr-2" : ""}${(fullName === authorName) ? " underline" : ""}`}
                >
                    {authorName}{(i !== authorArr.length -1) ? "," : "" }
                </span>
            ))}
        </>
    )
}

function PublicationCard ({publication, fullName}){
    return(
        <div className="flex flex-row w-full md:ml-8 mb-6">
            <div className="bg-black rounded-full w-4 mb-auto block my-2"></div>
            <div className="w-full flex flex-col md:ml-4">
                <span className="text-black text-base md:text-xl">
                    {publication.title}
                    <a 
                        href={"https://scholar.google.com/scholar?hl=zh-TW&as_sdt=0%2C5&q=" + encodeURIComponent(publication.title) + "&btnG="}
                        rel="noreferrer noopener"
                        target="_blank"
                        className="inline-block ml-2 mt-1 items-center"
                    >
                        <SiGooglescholar className="w-6 aspect-square text-black hover:opacity-25 transition-opacity duration-100" />
                    </a>
                    {!!publication.videolink ?
                        <a 
                            href={publication.videolink}
                            rel="noreferrer noopener"
                            target="_blank"
                            className="inline-block ml-2 mt-1 items-center"
                        >
                            <FaYoutube className="w-6 aspect-square text-black hover:opacity-25 transition-opacity duration-100" />
                        </a>
                        :""
                    }
                    {!!publication.dwlink ?
                        <a 
                            href={publication.dwlink}
                            rel="noreferrer noopener"
                            target="_blank"
                            className="inline-block ml-2 mt-1 items-center"
                        >
                            <RiFilePaper2Line className="w-6 aspect-square text-black hover:opacity-25 transition-opacity duration-100" />
                        </a>
                        :""
                    }
                </span>
                <div className="font-xs font-light text-gray-700 mb-1">
                    <AuthorSolver authors={publication.authors} fullName={fullName} />
                </div>
                <span className="text-gray-700 font-light font-xs">
                    {publication.proceedings}
                </span>
            </div>
            
            
        </div>
    );
}

function ResearchCat({publications, researchCat, fullName}){
    return(
        <div className="flex flex-col w-full my-6">
            <span className=" text-xl font-bold text-gray-700 mb-6">{researchCat.catname}</span>
            <div className="flex flex-col md:flex-row">
                <div className="md:w-1/3 flex md:aspect-square mb-6">
                    { (researchCat.coverImage !== null)?
                        <GatsbyImage
                            image={researchCat.coverImage.childImageSharp.gatsbyImageData}
                            alt={researchCat.catname}
                            imgStyle={{ objectFit: 'contain' }}
                            className="mb-auto"
                        /> :
                        <StaticImage
                            src="../content/notfound.png"
                            alt="head image not found"
                            className="mb-auto"
                        />
                    }
                </div>
                <div className="flex flex-col md:w-2/3">
                    {!!publications ?
                        publications.map((publication, i) => (
                            <PublicationCard
                                key={`cat_${researchCat.catname}_${i}`}
                                publication={publication}
                                fullName={fullName}
                            />
                        ))
                    :""
                    }
                </div>
            </div>
        </div>
    );
}

const ResearchProjects = ({ publications, researchCat, fullName }) => {
    const pubWithCat = [];

    for( var i=0 ; i<researchCat.length ; i++ ){
        pubWithCat[researchCat[i].catname] = [];
    }

    for( var i=0 ; i<publications.length ; i++ ){
        if( !!publications[i].researchCat ){
            pubWithCat[publications[i].researchCat].push(publications[i]);
        }
    }

    return (
    <div className="w-full my-6" id="Research">
        <div className="text-center text-black text-2xl md:text-4xl my-8">Research Projects</div>
        <div className="flex flex-col w-3/4 mx-auto">
            {!!researchCat ?
                researchCat.map((cat, i) => (
                    <ResearchCat 
                        key={`rcat_${i}`}
                        publications={pubWithCat[cat.catname]}
                        researchCat={cat}
                        fullName={fullName}
                    />
                ))
                :""
            }
        </div>
    </div>
    )
}

ResearchProjects.propTpyes = {
    publications: arrayOf(shape(PublicationType)),
    researchCat: arrayOf(shape(ResearchCatType)),
    fullName: String
}

export default ResearchProjects